@import url(https://use.typekit.net/cao1pdz.css);
@import url(https://use.typekit.net/ycu8xzp.css);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: bely-display, sans-serif !important;
  font-weight:300 !important;
  letter-spacing: 2px;
  font-size:48px;
  text-transform: lowercase !important;
}

p {
  font-family: poppins, sans-serif;
  letter-spacing: 1px;
  font-size:14px;
  color: #333333;
}

.flex { /*Flexbox for containers*/
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

.wrap {
  height: 200px;
  margin: 10px;
  display: -webkit-flex;
  display: flex;
}

.wrap span {
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.header {
  position:relative;  
  text-align:center;
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
}

.inner-header {
  height:85vh;
  width:100%;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; 
  -webkit-justify-content: center; 
          justify-content: center;  
  -webkit-flex-flow: column nowrap;  
          flex-flow: column nowrap;  
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
}

.default-button {
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  font-family: rooney-sans !important;
  font-weight: 200 !important;
  font-size: 24px;
  padding: 5px 25px;
  border: 2px solid rgba(88, 28, 255, .60);
  border-radius: 28px;
  background: rgb(138,97,248);
  background: linear-gradient(to right, rgba(138,97,248,1) 0%,rgba(236,95,231,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a61f8', endColorstr='#ec5fe7',GradientType=1 );
  transition: .3s;
  -webkit-animation: glow 3s infinite;
          animation: glow 3s infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;  
  -webkit-transition: .3s;  
}

@-webkit-keyframes glow{
  0% { box-shadow: 0 0 20px #8b61f844; text-shadow:  0 0 0px #fff; }
  40% { box-shadow: 0 0 100px #8b61f8ee; text-shadow:  0 0 20px #fff;}
  100% { box-shadow: 0 0 20px #8b61f844; text-shadow:  0 0 1px #fff; }
}

@keyframes glow{
  0% { box-shadow: 0 0 20px #8b61f844; text-shadow:  0 0 0px #fff; }
  40% { box-shadow: 0 0 100px #8b61f8ee; text-shadow:  0 0 20px #fff;}
  100% { box-shadow: 0 0 20px #8b61f844; text-shadow:  0 0 1px #fff; }
}

.default-button:hover {
  background-color: none;
  border-color: white;
  box-shadow: 0 0 10px #4b68da;  
}

/* Animation */

.parallax > use {
  -webkit-animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  -webkit-animation-duration: 13s;
          animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
}
@-webkit-keyframes move-forever {
  0% {
   -webkit-transform: translate3d(-90px,0,0);
           transform: translate3d(-90px,0,0);
  }
  100% { 
    -webkit-transform: translate3d(85px,0,0); 
            transform: translate3d(85px,0,0);
  }
}
@keyframes move-forever {
  0% {
   -webkit-transform: translate3d(-90px,0,0);
           transform: translate3d(-90px,0,0);
  }
  100% { 
    -webkit-transform: translate3d(85px,0,0); 
            transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }

  h1 {
    font-size: 85px !important;
    padding: 15px;
  }

  p {
    font-size: 25px !important;
    padding: 15px;
  }
}

